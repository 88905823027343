import Axios from 'axios';

import { API_URL } from '@/constants';
import { getToken } from './utils/auth';

export function ensureHttpsProtocol(url: string) {
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  } else {
    return url;
  }
}

const api = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

// Asynchroniczny interceptor
api.interceptors.request.use(
  async (config) => {
    const token = getToken();

    // Pobierz token CSRF, jeśli metoda nie jest 'get'
    if (config.method !== 'get') {
      try {
        const response = await api.get('csrf/');
        const csrfToken = response.data.csrfToken;
        config.headers['X-CSRFToken'] = csrfToken;
      } catch (error) {
        // Obsłuż błąd pobierania tokenu CSRF, jeśli zajdzie potrzeba
        console.error('Error fetching CSRF token:', error);
      }
    }

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
