export const API_URL = 'https://api.pomarancze.com/' ?? 'http://localhost:8080';

export const modalIds = {
  basic: 'basic-modal',
  checkout: 'checkout',
};

export const QUERY_ID = {
  GET_CART: 'GET_CART',
  GET_ORDERS: 'GET_ORDERS',
  GET_PROFILE: 'PROFILE',
  GET_LINK: 'GET_LINK',
  GET_POSTS: 'GET_POSTS',
  GET_POST: 'GET_POST',
};

export const products = [
  {
    id: 1,
    title: 'Soczyste pomarańcze',
    name: 'Pomarańcze',
    desc: 'Świeże, pełne smaku pomarańcze, które zachwycą każdego.',
    country: 'Hiszpania',
    price: 120,
    weight: 10,
    unit: 'kg',
    shopPageImg: '/oranges.png',
    image: '/our-product-bg-1.png',
    route: '/shop/1',
  },
  {
    id: 2,
    title: 'Soczyste cytryny',
    name: 'Cytryny',
    desc: 'Idealne do soków, potraw i jako zdrowa przekąska.',
    country: 'Hiszpania',
    price: 120,
    weight: 10,
    unit: 'kg',
    shopPageImg: '/lemons.png',
    image: '/our-product-bg-2.png',
    route: '/shop/2',
  },
  {
    id: 3,
    title: 'Oliwa z oliwek',
    name: 'Oliwa z oliwek',
    desc: 'Wysokiej jakości oliwa, która doda wyjątkowego smaku każdemu daniu.',
    country: 'Hiszpania',
    price: 70,
    weight: 1,
    unit: 'L',
    shopPageImg: '/oil.png',
    image: '/our-product-bg-3.png',
    route: '/shop/3',
  },
];

export const courierOptions = [
  {
    name: 'courier',
    value: 'DPD',
    title: 'Kurier DPD 1-2 Dni Roboczych',
    price: 16,
    image: '/dpd.png',
  },
  {
    name: 'courier',
    value: 'PoczteX',
    title: 'Kurier PoczteX 2-3 Dni Roboczych',
    price: 16,
    image: '/poczta.png',
  },
  {
    name: 'courier',
    value: 'Paczkomat',
    title: 'Paczkomaty InPost 24/7',
    price: 16,
    image: '/dpd.png',
  },
  {
    name: 'courier',
    value: 'InPost',
    title: 'Kurier InPost 1-2 Dni Roboczych',
    price: 16,
    image: '/dpd.png',
  },
  {
    name: 'courier',
    value: 'Orlen',
    title: 'Orlen Paczka',
    price: 16,
    image: '/orlen.png',
  },
];
