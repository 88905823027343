import API from '@/api';
import { products, QUERY_ID } from '@/constants';
import { CartResponse } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

function CheckoutItem(props: {
  cart: number;
  id: number;
  quantity: number;
  product: {
    id: number;
    name: string;
    price: string;
    weight: string;
  };
}) {
  const selectedItem = products.filter(
    (item) => item.id == props.product.id,
  )[0];

  return (
    <div className="flex flex-row items-center gap-4 border border-solid border-t-0 border-l-0 border-b-0 border-r-0 md:border-r-1 border-[#D9D9D9] md:pr-2">
      {selectedItem && (
        <>
          <img
            alt="item-image"
            width={79}
            height={79}
            src={selectedItem.shopPageImg}
          />
          <div className="flex flex-col gap-4">
            <div className="text-[16px]">
              {props.quantity * Number(props.product.weight)}{' '}
              {selectedItem.unit}
            </div>
            <div className="text-[16px] font-bold">{selectedItem.name}</div>
          </div>
        </>
      )}
    </div>
  );
}

export function CheckoutModal() {
  const [fetchedCart, setFetchedCart] = useState<CartResponse | undefined>();
  const [calculatedPrice, setCalculatedPrice] = useState<number | undefined>();

  const { data: cart } = useQuery({
    queryKey: [QUERY_ID.GET_CART],
    queryFn: () => API.get('cart/').then((res) => res.data as CartResponse),
  });

  useEffect(() => {
    if (cart) {
      setFetchedCart(cart);
    }
  }, [cart]);

  return (
    <div
      id="checkout-modal"
      className="!hidden fixed bottom-0 left-0 w-[99%] xl:gap-4 pointer-events-auto z-20 flex flex-col gap-4 transition-all duration-300"
    >
      <button
        className="w-[165px] bg-[#F47E01] rounded-[61px] btn btn-sm btn-circle btn-ghost text-white ml-4"
        onClick={() =>
          (
            document.getElementById('checkout-modal') as HTMLDivElement | null
          )?.classList.add('!hidden')
        }
      >
        <div>Zwiń koszyk</div>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6L8 10L12 6"
            stroke="#ffffff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {fetchedCart && (
        <div className="bg-white w-full flex flex-row flex-wrap items-center xl:justify-between p-4 gap-8 shadow-inner">
          <div className="flex flex-row flex-wrap gap-4">
            {fetchedCart.items.slice(0, 3).map((item, index) => (
              <CheckoutItem key={index} {...item} />
            ))}
            {fetchedCart.items.length == 0 && (
              <div className="text-[20px] text-black font-bold">
                Brak produktów
              </div>
            )}
            {fetchedCart.items.length > 3 && (
              <div className="w-[79px] h-[79px] flex flex-col justify-center items-center text-[25px] font-bold text-[#B0B0B0] bg-[#D8D8D8] rounded-[15px]">
                +{fetchedCart.items.length - 3}
              </div>
            )}
          </div>

          <div className="flex flex-row items-center self-center bg-[#E9E9E9] h-[63px] rounded-[65px]">
            <div className="flex flex-col px-8">
              <div className="text-[#6B6B6B] text-[16px]">Do zapłaty</div>
              <div className="font-bold text-[14px] md:text-[20px] text-[#F47E01]">
                {fetchedCart.discounted_price} ZŁ
              </div>
            </div>
            <button
              onClick={() => window.location.assign('/checkout')}
              disabled={fetchedCart.items.length == 0}
              className={`${fetchedCart.items.length == 0 ? 'cursor-not-allowed' : 'cursor-pointer'} uppercase text-[12px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#9BC918] outline-none border-none rounded-[63px] h-[63px] px-4`}
            >
              <div>złóż zamówienie</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
