export default function SocialIcons() {
  return (
    <div className="flex flex-row gap-4 items-center">
      <svg
        className="cursor-pointer"
        onClick={() =>
          window.open(
            'https://www.instagram.com/soczyste_pomarancze_z_lisciem?igsh=cTNiNjEzdW1iaW95',
            '_blank',
          )
        }
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_920_65)">
          <path
            d="M36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36C27.9411 36 36 27.9411 36 18Z"
            fill="url(#paint0_linear_920_65)"
          />
          <path
            opacity="0.1"
            d="M31.5472 6.15826C28.923 10.9756 25.2116 15.9446 20.5743 20.5819C15.9369 25.2193 10.968 28.9235 6.15769 31.5477C5.85611 31.2856 5.56322 31.0125 5.27901 30.7283C3.56109 29.0677 2.19096 27.0817 1.24852 24.8862C0.30607 22.6906 -0.189834 20.3294 -0.210278 17.9402C-0.230723 15.5509 0.2247 13.1816 1.12944 10.9702C2.03417 8.75883 3.37011 6.74971 5.05936 5.05999C6.74861 3.37027 8.75736 2.03376 10.9685 1.12841C13.1796 0.223057 15.5488 -0.233028 17.9381 -0.21325C20.3273 -0.193473 22.6886 0.301771 24.8845 1.2436C27.0803 2.18544 29.0666 3.55501 30.7277 5.27247C31.0119 5.55668 31.2851 5.85194 31.5472 6.15826Z"
            fill="white"
          />
          <path
            d="M22.2627 8.52637H13.7364C12.3545 8.52637 11.0292 9.07533 10.052 10.0525C9.07484 11.0297 8.52588 12.355 8.52588 13.7369V22.2632C8.52588 23.6451 9.07484 24.9704 10.052 25.9476C11.0292 26.9248 12.3545 27.4737 13.7364 27.4737H22.2627C23.6446 27.4737 24.97 26.9248 25.9471 25.9476C26.9243 24.9704 27.4732 23.6451 27.4732 22.2632V13.7369C27.4732 12.355 26.9243 11.0297 25.9471 10.0525C24.97 9.07533 23.6446 8.52637 22.2627 8.52637ZM25.8154 21.5148C25.8154 22.6555 25.3622 23.7495 24.5556 24.5561C23.749 25.3627 22.655 25.8158 21.5143 25.8158H14.4848C13.3441 25.8158 12.2501 25.3627 11.4435 24.5561C10.6369 23.7495 10.1838 22.6555 10.1838 21.5148V14.4853C10.1838 13.3446 10.6369 12.2506 11.4435 11.444C12.2501 10.6374 13.3441 10.1843 14.4848 10.1843H21.5143C22.655 10.1843 23.749 10.6374 24.5556 11.444C25.3622 12.2506 25.8154 13.3446 25.8154 14.4853V21.5148Z"
            fill="white"
          />
          <path
            d="M21.4549 14.58L21.4099 14.535L21.372 14.4971C20.4765 13.6047 19.2636 13.1039 17.9993 13.1045C17.3609 13.1088 16.7296 13.2389 16.1415 13.4873C15.5533 13.7357 15.0199 14.0976 14.5716 14.5522C14.1234 15.0068 13.7691 15.5453 13.529 16.1369C13.289 16.7285 13.1678 17.3616 13.1725 18C13.1715 19.2957 13.6823 20.5393 14.5935 21.4603C15.0399 21.9121 15.5718 22.2704 16.1582 22.5144C16.7446 22.7584 17.3737 22.8831 18.0088 22.8813C18.9615 22.8614 19.8877 22.5646 20.6746 22.0272C21.4616 21.4899 22.0751 20.7351 22.4404 19.8551C22.8057 18.975 22.9071 18.0076 22.7321 17.071C22.5571 16.1343 22.1133 15.2688 21.4549 14.58ZM17.9993 21.2116C17.3623 21.2205 16.737 21.0398 16.203 20.6924C15.669 20.3449 15.2503 19.8465 15.0003 19.2605C14.7503 18.6745 14.6803 18.0274 14.7991 17.4015C14.9179 16.7756 15.2201 16.1991 15.6674 15.7455C16.1147 15.2918 16.6869 14.9815 17.3111 14.8538C17.9352 14.7262 18.5833 14.7871 19.1727 15.0288C19.7622 15.2705 20.2665 15.6821 20.6214 16.2112C20.9763 16.7402 21.1659 17.3629 21.1659 18C21.169 18.4189 21.0896 18.8342 20.9321 19.2223C20.7746 19.6105 20.5422 19.9637 20.2481 20.262C19.9541 20.5603 19.6041 20.7976 19.2182 20.9606C18.8324 21.1235 18.4182 21.2088 17.9993 21.2116Z"
            fill="white"
          />
          <path
            d="M24.2591 12.8793C24.2603 13.0313 24.2316 13.182 24.1745 13.3228C24.1174 13.4637 24.0331 13.5919 23.9264 13.7002C23.8197 13.8084 23.6927 13.8945 23.5527 13.9536C23.4127 14.0127 23.2624 14.0436 23.1104 14.0445C22.9597 14.0445 22.8105 14.0145 22.6714 13.9564C22.5324 13.8982 22.4063 13.8131 22.3004 13.7059C22.1399 13.5431 22.0308 13.3367 21.9865 13.1125C21.9422 12.8883 21.9647 12.6559 22.0512 12.4444C22.1377 12.2328 22.2844 12.0513 22.4731 11.9223C22.6618 11.7933 22.8842 11.7225 23.1127 11.7188C23.3802 11.7187 23.6393 11.8126 23.8446 11.984L23.8683 12.0077C23.9065 12.0388 23.9414 12.0737 23.9725 12.1119L23.9985 12.1403C24.1681 12.3492 24.2601 12.6103 24.2591 12.8793Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_920_65"
            x1="5.27211"
            y1="5.27211"
            x2="30.7279"
            y2="30.7279"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAE100" />
            <stop offset="0.15" stopColor="#FCB720" />
            <stop offset="0.3" stopColor="#FF7950" />
            <stop offset="0.5" stopColor="#FF1C74" />
            <stop offset="1" stopColor="#6C1CD1" />
          </linearGradient>
          <clipPath id="clip0_920_65">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        className="cursor-pointer"
        onClick={() =>
          window.open(
            'https://www.tiktok.com/@pomarancze.com?_t=8oHmJx5mEa1&_r=1',
            '_blank',
          )
        }
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_920_79)">
          <path
            d="M18 0.132812C14.4661 0.132812 11.0116 1.18073 8.07325 3.14405C5.13493 5.10738 2.84479 7.89792 1.49243 11.1628C0.140066 14.4277 -0.213773 18.0203 0.475654 21.4863C1.16508 24.9523 2.86681 28.136 5.36564 30.6348C7.86448 33.1336 11.0482 34.8354 14.5142 35.5248C17.9802 36.2142 21.5727 35.8604 24.8376 34.508C28.1025 33.1557 30.8931 30.8655 32.8564 27.9272C34.8197 24.9889 35.8676 21.5343 35.8676 18.0005C35.8659 13.2622 33.9829 8.71848 30.6324 5.36802C27.282 2.01756 22.7382 0.134527 18 0.132812ZM26.2787 14.3197C25.0213 14.3217 23.7867 13.9842 22.7051 13.343V22.2291C22.706 23.0819 22.5386 23.9265 22.2127 24.7145C21.8867 25.5025 21.4085 26.2185 20.8056 26.8215C20.2026 27.4245 19.4866 27.9026 18.6985 28.2286C17.9105 28.5545 17.066 28.7219 16.2132 28.721C15.749 28.7207 15.286 28.6728 14.8314 28.5781C13.2655 28.2377 11.8808 27.3303 10.9434 26.0305C10.0061 24.7307 9.58246 23.1303 9.75391 21.5369C9.92536 19.9436 10.6798 18.47 11.8722 17.3993C13.0646 16.3287 14.6107 15.7367 16.2132 15.7372V19.2155C15.7647 19.2161 15.3218 19.3158 14.9162 19.5073C14.5106 19.6988 14.1522 19.9774 13.8666 20.3233C13.5781 20.6785 13.3745 21.095 13.2712 21.5408C13.168 21.9867 13.168 22.4503 13.271 22.8962C13.3875 23.4558 13.6649 23.9691 14.0691 24.3733C14.4732 24.7774 14.9866 25.0548 15.5462 25.1713C15.9878 25.2738 16.447 25.2748 16.8892 25.1745C17.3313 25.0741 17.745 24.8749 18.0992 24.5918C18.4533 24.3087 18.7388 23.949 18.9341 23.5398C19.1294 23.1306 19.2295 22.6825 19.2269 22.2291V7.37517H19.2388C19.2388 7.33943 19.227 7.31561 19.227 7.27987H22.7052C22.708 8.22678 23.0853 9.13411 23.7549 9.80367C24.4245 10.4732 25.3318 10.8506 26.2787 10.8534L26.2787 14.3197Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_920_79">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
